import * as React from "react";
import { Link } from "gatsby";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { PageHeaderWithGakka } from "../PageHeader/PageHeader";
import Button from "../Button/Button";
import Hr from "../Hr/Hr";
import { ResponsiveBlock } from "../../layouts/ResponsiveBlock/ResponsiveBlock";
import { useGakka } from "../../../hooks/useGakka/useGakka";

const OpenCampus = ({ body, openCampusData }: any) => {
  const gakka = useGakka();
  return (
    <>
      <PageHeaderWithGakka title="オープンキャンパス" invertTitle />
      <ResponsiveBlock bgColor="">
        <div className="tw-pt-6 tw-px-4 tw-pb-16 md:tw-mt-12">
          <p className="tw-text-sm md:tw-text-2xl">{body}</p>
          <div className="tw-mt-16 md:tw-mt-16">
            {openCampusData.map((item: any, index: any) => (
              <div
                key={index}
                className={`tw-w-[320px] tw-mt-8 tw-mx-auto tw-px-8 tw-py-2 tw-border tw-border-${item?.color} tw-rounded-full md:tw-w-[640px] md:tw-px-8 md:tw-py-5`}
              >
                <Link to={`${gakka.link}/opencampus/${item.id}/`}>
                  <div className="tw-flex tw-justify-between tw-items-center">
                    <span
                      className={`tw-text-${item?.color} tw-text-base tw-font-bold tw-flex tw-flex-wrap md:tw-flex-nowrap md:tw-text-2xl`}
                    >
                      <span className="tw-flex-grow tw-w-full md:tw-w-auto">
                        {item.ja}
                      </span>
                      <span className="tw-flex-grow tw-w-full md:tw-w-auto">
                        {item.ja2}
                      </span>
                    </span>
                    <div>
                      <CalendarDaysIcon
                        className={`tw-w-8 tw-h-8 tw-text-${item?.color}`}
                      />
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </ResponsiveBlock>
      <Hr />
      <div className="tw-pt-10 tw-pb-20 tw-text-center">
        <Button name="戻る" url={gakka.link}></Button>
      </div>
    </>
  );
};

export default OpenCampus;
