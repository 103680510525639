import * as React from "react";
import { graphql, HeadFC } from "gatsby";
import OpenCampusElement from "../../../components/elements/OpenCampusElement/OpenCampusElement";
import Layout from "../../../components/layouts/Default/Default";
import { ResponsiveBlock } from "../../../components/layouts/ResponsiveBlock/ResponsiveBlock";
import { CommonHead } from "../../../components/commons/Head/Head";

export const query = graphql`
  query {
    allSettingsJson {
      nodes {
        id
        siteTitle
      }
    }
  }
`;

const openCampusData = [
  {
    ja: "韓国語＋英語専攻の",
    ja2: "レッスン体験に参加する",
    id: "lesson-cal",
    color: "baseNavColor",
  },
  {
    ja: "韓国語＋英語専攻説明に",
    ja2: "参加する",
    id: "briefing-cal",
    color: "baseNavColor",
  },
  {
    ja: "オンラインで",
    ja2: "説明を聞きたい",
    id: "online-cal",
    color: "baseNavColor",
  },
  {
    ja: "保護者対象の説明を",
    ja2: "聞きたい",
    id: "parent-briefing-cal",
    color: "baseMainColor",
  },
];

const OpenCampus = ({ data }: any) => {
  const title = data.allSettingsJson?.nodes[0].siteTitle;
  return (
    <Layout title={title} pageType="TopPage">
      <OpenCampusElement
        openCampusData={openCampusData}
        body="ご希望のメニューから参加申込をお願い致します。"
      />
    </Layout>
  );
};

export default OpenCampus;

export const Head: HeadFC = ({ data }: any) => {
  return (
    <CommonHead
      ogTitle={`オープンキャンパス - 韓国語＋英語専攻 - ${data.allSettingsJson?.nodes[0].siteTitle}`}
    >
      <title>
        オープンキャンパス - 韓国語＋英語専攻 -
        {data.allSettingsJson?.nodes[0].siteTitle}
      </title>
    </CommonHead>
  );
};
