import * as React from "react";
import { Link } from "gatsby";

const Button = ({ url, name }: any) => {
  return (
    <Link to={url}>
      <button className="tw-px-8 tw-py-1 tw-bg-baseWhite tw-border-2 tw-border-baseBlue tw-rounded-full tw-text-baseBlue tw-text-xs tw-font-bold md:tw-py-0 md:tw-text-2xl">
        {name}
      </button>
    </Link>
  );
};

export default Button;
